<template>
  <div>
    <v-text-field
      label="Número do banco*"
      required
      outlined
      :rules="rules.baseNumberRules"
      :value="battery.baseNumber"
      @input="changeBaseNumber($event)"
    />

    <v-text-field
      label="Modelo da bateria*"
      required
      outlined
      :rules="rules.batteryModelRules"
      :value="battery.batteryModel"
      @input="changeBatteryModel($event)"
    />

    <div class="batteries-codes ma-0">
      <v-text-field
        class="battery1"
        label="Código da bateria 1*"
        required
        outlined
        :rules="rules.battery1CodeRules"
        :value="battery.battery1Code"
        @input="changeBattery1Code($event)"
      />
      <v-text-field
        class="battery2"
        label="Código da bateria 2*"
        required
        outlined
        :rules="rules.battery2CodeRules"
        :value="battery.battery2Code"
        @input="changeBattery2Code($event)"
      />
      <v-text-field
        label="Código da bateria 3*"
        class="battery3"
        required
        outlined
        :rules="rules.battery3CodeRules"
        :value="battery.battery3Code"
        @input="changeBattery3Code($event)"
      />
      <v-text-field
        class="battery4"
        label="Código da bateria 4*"
        required
        outlined
        :rules="rules.battery4CodeRules"
        :value="battery.battery4Code"
        @input="changeBattery4Code($event)"
      />
    </div>

    <v-text-field
      type="date"
      label="Data de instalação da bateria*"
      required
      outlined
      :rules="rules.instalationDateRules"
      :value="battery.instalationDate"
      @input="changeInstalationDate($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'BatteryForm',
  props: {
    value: {
      type: Object,
      required: true
    },
    rules: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      battery: {  
        baseNumber: this.value.baseNumber,
        batteryModel: this.value.batteryModel,
        battery1Code: this.value.battery1Code,
        battery2Code: this.value.battery2Code,
        battery3Code: this.value.battery3Code,
        battery4Code: this.value.battery4Code,
        instalationDate: this.value.instalationDate,
      },
    }
  },
  methods: {
    changeBaseNumber(newValue) {
      this.battery.baseNumber = newValue;
      this.updateBattery();
    },
    changeBatteryModel(newValue) {
      this.battery.batteryModel = newValue;
      this.updateBattery();
    },
    changeBattery1Code(newValue) {
      this.battery.battery1Code = newValue;
      this.updateBattery();
    },
    changeBattery2Code(newValue) {
      this.battery.battery2Code = newValue;
      this.updateBattery();
    },
    changeBattery3Code(newValue) {
      this.battery.battery3Code = newValue;
      this.updateBattery();
    },
    changeBattery4Code(newValue) {
      this.battery.battery4Code = newValue;
      this.updateBattery();
    },
    changeInstalationDate(newValue) {
      this.battery.instalationDate = newValue;
      this.updateBattery();
    },
    updateBattery() {
      this.$emit('input', this.battery);
    },
  }
}
</script>
