<template>
  <v-container fluid>
    <v-form
      v-model="valid"
      @submit="submitForm"
    >
      <v-container>
        <div class="form-grid">
          <v-card color="gridLeft primary">
            <v-card-text>
              <site-form
                v-model="site"
                :rules="siteRules"
              />
              <battery-form
                v-model="battery"
                :rules="batteryRules"
              />
            </v-card-text>
          </v-card>
          <v-card color="gridRight primary">
            <v-card-text>
              <alerts-form
                v-model="alerts"
                :rules="alertsRules"
              />
            </v-card-text>
          </v-card>
          <div class="actions d-flex flex-row justify-space-between">
            <v-btn @click="goHome">
              Voltar
            </v-btn>
            <v-btn
              :disabled="!valid"
              @click="submitForm"
            >
              Adicionar
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ROUTE_NAME } from '@/router'
import SiteForm from './parts/SiteForm'
import BatteryForm from './parts/BatteryForm'
import AlertsForm from './parts/AlertsForm'

export default {
  name: 'AddSite',
  components: {
    SiteForm,
    BatteryForm,
    AlertsForm,
  },
  data() {
    return {
      valid: false,
      site: {
        id: '',
        address: '',
        city: '',
        uf: '',
      },
      battery: {
        baseNumber: '',
        batteryModel: '',
        battery1Code: '',
        battery2Code: '',
        battery3Code: '',
        battery4Code: '',
        instalationDate: '',
      },
      alerts: {
        individualVoltageMin: '',
        individualVoltageMax: '',
        currentData: '',
        temperatureMin: '',
        temperatureMax: '',
      },
      siteRules: {
        idRules: [value => !!value || 'Número do equipamento é obrigatório.'],
        addressRules: [value => !!value || 'Endereço é obrigatório.'],
        cityRules: [value => !!value || 'Cidade é obrigatória.'],
        ufRules: [value => !!value || 'UF é obrigatório.'],
      },
      batteryRules: {
        baseNumberRules: [value => !!value || 'Número do banco é obrigatório.'],
        batteryModelRules: [value => !!value || 'Modelo é obrigatório.'],
        battery1CodeRules: [value => !!value || 'Código da bateria 1 é obrigatório.'],
        battery2CodeRules: [value => !!value || 'Código da bateria 2 é obrigatório.'],
        battery3CodeRules: [value => !!value || 'Código da bateria 3 é obrigatório.'],
        battery4CodeRules: [value => !!value || 'Código da bateria 4 é obrigatório.'],
        instalationDateRules: [value => !!value || 'Data de instalação é obrigatória.'],
      },
      alertsRules: {
        individualVoltageMinRules: [value => !!value || 'Dado de tensão inferior é obrigatório.'],
        individualVoltageMaxRules: [value => !!value || 'Dado de tensão superior é obrigatório.'],
        currentDataRules: [value => !!value || 'Dado de corrente é obrigatório.'],
        temperatureMinRules: [value => !!value || 'Temperatura inferior é obrigatória.'],
        temperatureMaxRules: [value => !!value || 'Temperatura superior é obrigatória.'],
      },
    }
  },
  methods: {
    ...mapActions([
      'addSite',
      'clearAddSite'
    ]),
    submitForm() {
      if(this.valid) {
        const siteInfo = {...this.site, ...this.battery, ...this.alerts}
        // const siteInfo = {...this.site, ...this.battery, ...this.alerts, ...this.report}
        const res = this.addSite({that: this, site: siteInfo});
        if (res) {
          this.clearForm();
          this.goHome();
        }
        // else // TODO: Add toast informing about failed request
      }
    },
    clearForm() {
      this.site = {
        id: '',
        address: '',
        city: '',
        uf: '',
      }
      this.battery = {
        baseNumber: '',
        batteryModel: '',
        battery1Code: '',
        battery2Code: '',
        battery3Code: '',
        battery4Code: '',
        instalationDate: '',
      }
      this.alerts = {
        individualVoltageMin: '',
        individualVoltageMax: '',
        currentData: '',
        temperatureMin: '',
        temperatureMax: '',
      }
    },
    goHome() {
      this.clearAddSite();
      this.$router.push({name: ROUTE_NAME.OVERVIEW});
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';
@import '@/styles/mixins';

.form-grid {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "gridLeft"
    "gridRight"
    "actions";

  @include md-and-up{
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "gridLeft   gridRight"
      "actions    actions";

    .md-and-up-mr-4 {
      margin-right: 16px;
    }
  }
}
.gridLeft {
  grid-area: gridLeft;
}
.gridRight {
  grid-area: gridRight;
}
.actions {
  grid-area: actions;
}

.batteries-codes {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    "battery1"
    "battery2"
    "battery3"
    "battery4";

  @include sm-and-up{
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "battery1   battery2"
      "battery3    battery4";
  }
}

.card-title {
  margin-bottom: 25px;
}

.v-input--is-focused.theme--light {
  color: rgba(0, 0, 0, 0.87) !important;
  caret-color: rgba(0, 0, 0, 0.87) !important;
  label {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
.v-input--is-focused.theme--dark {
  color: inherit !important;
  caret-color: inherit !important;
  label {
    color: inherit !important;
  }
}

.v-btn.theme--light {
  background-color: map-get($material-dark, 'secondary-light') !important;
  color: white !important;
}
.v-btn.theme--dark {
  background-color: map-get($material-dark, 'primary') !important;
  color: inherit !important;
  .v-icon {
    color: white !important;
  }
}
</style>
