<template>
  <div>
    <h2 class="text-center mt-4 card-title">
      Alarmes
    </h2>

    <div class="row ma-0">
      <v-text-field
        class="md-and-up-mr-4"
        label="Tensão individual inferior*"
        required
        outlined
        :rules="rules.individualVoltageMinRules"
        :value="alerts.individualVoltageMin"
        @input="changeIndividualVoltageMin($event)"
      />
      <v-text-field
        label="Tensão individual superior*"
        required
        outlined
        :rules="rules.individualVoltageMaxRules"
        :value="alerts.individualVoltageMax"
        @input="changeIndividualVoltageMax($event)"
      />
    </div>

    <v-text-field
      label="Dados de corrente*"
      required
      outlined
      :rules="rules.currentDataRules"
      :value="alerts.currentData"
      @input="changeCurrentData($event)"
    />

    <div class="row ma-0">
      <v-text-field
        class="md-and-up-mr-4"
        label="Temperatura inferior*"
        required
        outlined
        :rules="rules.temperatureMinRules"
        :value="alerts.temperatureMin"
        @input="changeTemperatureMin($event)"
      />
      <v-text-field
        label="Temperatura superior*"
        required
        outlined
        :rules="rules.temperatureMaxRules"
        :value="alerts.temperatureMax"
        @input="changeTemperatureMax($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertsForm',
  props: {
    value: {
      type: Object,
      required: true
    },
    rules: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alerts: {
        individualVoltageMin: this.value.individualVoltageMin,
        individualVoltageMax: this.value.individualVoltageMax,
        currentData: this.value.currentData,
        temperatureMin: this.value.temperatureMin,
        temperatureMax: this.value.temperatureMax,
      }
    }
  },
  methods: {
    changeIndividualVoltageMin(newValue) {
      this.alerts.individualVoltageMin = newValue;
      this.updateAlerts();
    },
    changeIndividualVoltageMax(newValue) {
      this.alerts.individualVoltageMax = newValue;
      this.updateAlerts();
    },
    changeCurrentData(newValue) {
      this.alerts.currentData = newValue;
      this.updateAlerts();
    },
    changeTemperatureMin(newValue) {
      this.alerts.temperatureMin = newValue;
      this.updateAlerts();
    },
    changeTemperatureMax(newValue) {
      this.alerts.temperatureMax = newValue;
      this.updateAlerts();
    },
    updateAlerts() {
      this.$emit('input', this.alerts);
    },
  }
}
</script>
