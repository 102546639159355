<template>
  <div>
    <h2 class="text-center mt-4 card-title">
      Parâmetros do Site
    </h2>
    <v-text-field
      label="ID do equipamento Conan*"
      required
      outlined
      :rules="rules.idRules"
      :value="site.id"
      @input="changeId($event)"
    />

    <v-text-field
      label="Endereço*"
      required
      outlined
      :rules="rules.addressRules"
      :value="site.address"
      @input="changeAddress($event)"
    />

    <div class="row ma-0">
      <v-text-field
        class="md-and-up-mr-4"
        label="Cidade*"
        required
        outlined
        :rules="rules.cityRules"
        :value="site.city"
        @input="changeCity($event)"
      />
      <v-text-field
        label="UF*"
        required
        outlined
        :rules="rules.ufRules"
        :value="site.uf"
        @input="changeUf($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteForm',
  props: {
    value: {
      type: Object,
      required: true
    },
    rules: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      site: {
        id: this.value.id,
        address: this.value.address,
        city: this.value.city,
        uf: this.value.uf,
      },
    }
  },
  methods: {
    changeId(newValue) {
      this.site.id = newValue;
      this.updateSite();
    },
    changeAddress(newValue) {
      this.site.address = newValue;
      this.updateSite();
    },
    changeCity(newValue) {
      this.site.city = newValue;
      this.updateSite();
    },
    changeUf(newValue) {
      this.site.uf = newValue;
      this.updateSite();
    },
    updateSite() {
      this.$emit('input', this.site);
    },
  }
}
</script>
